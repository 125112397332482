/** Blue Theme CSS - Temperary as this was quicker than additional logic into Calc repo. We may even redo calc into this repo at some point? */
.banner-blue-theme #calculator-container .finance-calculator-body,
.banner-blue-theme #calculator-container .representative-example.representative-text {
  background-color: #e7e9fb;
}

.banner-blue-theme #calculator-container h2,
.banner-blue-theme #calculator-container h4,
.banner-blue-theme #calculator-container label,
.banner-blue-theme #calculator-container span,
.banner-blue-theme #calculator-container p,
.banner-blue-theme #calculator-container svg {
  color: #0c1253;
}

.banner-blue-theme #calculator-container .calculator-sliders .MuiSlider-track,
.banner-blue-theme #calculator-container .calculator-sliders .MuiSlider-valueLabel,
.banner-blue-theme #calculator-container .calculator-sliders .MuiSlider-thumb {
  background-color: #1f2ed1;
}

.banner-blue-theme #calculator-container .calculator-sliders .MuiSlider-valueLabel {
  border-radius: 50%;
}

.banner-blue-theme #calculator-container .calculator-sliders span.MuiSlider-valueLabelLabel {
  color: #fff;
}

.banner-blue-theme #calculator-container .MuiSlider-rail {
  background-color: #ccc;
}

.banner-blue-theme #calculator-container .calculator-sliders .MuiSlider-thumb.Mui-active,
.banner-blue-theme #calculator-container .calculator-sliders .MuiSlider-thumb.Mui-focusVisible,
.banner-blue-theme #calculator-container .calculator-sliders .MuiSlider-thumb:hover {
  box-shadow: 0px 0px 0px 14px rgba(31, 46, 209, 0.16);
}

.banner-blue-theme #calculator-container .tooltip-content,
.banner-blue-theme #calculator-container .tooltip-content::before {
  background-color: #d0d3f8 !important;
}

.banner-blue-theme #calculator-container .tooltip-content p {
  color: #0c1253;
}

.banner-blue-theme #calculator-container .finance-calculator-body .button.personalised-quote-btn {
  background-color: #1f2ed1;
  color: #fff;
  box-shadow: unset;
  outline: unset;
  border: none;
}

.banner-blue-theme
  #calculator-container
  .finance-calculator-body
  .button.personalised-quote-btn
  span {
  color: #fff;
  margin-right: 0;
}

.banner-blue-theme
  #calculator-container
  .finance-calculator-body
  .button.personalised-quote-btn
  svg {
  display: none;
}

.banner-blue-theme
  #calculator-container
  .finance-calculator-body
  .button.personalised-quote-btn:hover {
  background-color: #131c7d;
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}
