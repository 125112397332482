/** The below CSS has been copy/pasted from Zuto.css. Only included what looks to be needed. **/
.V1 .calculator-body .summary p {
  margin-bottom: 16px;
}

.V1 .calculator-body p.large {
  font-size: 1.125rem;
  line-height: 1.625rem;
}

.V1 .calculator-body .summary button {
  color: #fff;
  border: unset;
  font-family: 'Apax Regular', Helvetica, sans-serif;
}

.V1 .calculator-body .summary h2 {
  font-size: 1.625rem;
  line-height: 1.875rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 20px;
}

.V1 .calculator-body .radio-container input:checked + span {
  background: #0c9ce4;
  color: #fff;
  box-shadow: inset 0 0 0 1px #0c9ce4;
  font-family: 'Apax Bold', Helvetica, sans-serif;
}

.V1 .calculator-body .inline-fields label.radio-container span {
  height: 3.125rem;
  line-height: 3.125rem;
  display: block;
  width: auto;
  font-weight: 400;
  max-width: 100%;
  border: none;
  width: auto;
  border-radius: 3px;
  text-align: center;
}

.V1 .calculator-body .credit-score .information-bubble {
  font-size: 14px;
}

.V1 .calculator-body .radio-container span {
  border: none;
  box-shadow: inset 0 0 0 1px #d6d6d6;
}

.V1 .calculator-body .checkbox.inline-fields label,
.V1 .radio.inline-fields label {
  float: none;
  display: inline-block;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 1.5% 0 0;
  cursor: pointer;
}

.V1 .calculator-body .credit-score .radio-container input:checked + span {
  position: relative;
  margin-bottom: 1rem;
}

.V1 .calculator-body .radio-container input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

.V1 .calculator-body .credit-score .radio-container input:checked + span:after {
  position: absolute;
  bottom: 0;
  left: 44%;
  content: ' ';
  top: 115%;
  width: 0;
  height: 0;
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
  border-bottom: 10px solid #e8f6fd;
}

.V1 .calculator-body .checkbox.inline-fields,
.V1 .calculator-body .radio.inline-fields {
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.V1 .calculator-body input,
.V1 .calculator-body select,
.V1 .calculator-body textarea {
  display: inline-block;
  width: 100%;
  height: 3.125rem;
  font-size: 1rem;
  padding: 0 0.75em;
  vertical-align: middle;
  font-weight: 400;
  color: #33383a;
  background: #fff;
  max-width: 100%;
  border: none;
  box-shadow: inset 0 0 0 1px #d6d6d6;
  appearance: none;
}

.V1 .calculator-body .field-container .input-with-icon .field-icon + input {
  text-indent: 1em;
  padding-left: 1em;
  padding-top: 1px;
}

.V1 .calculator-body .inline-questions {
  margin-bottom: 1rem;
}

.V1 .calculator-body .field-container .input-with-icon .field-icon {
  line-height: 55px;
  position: absolute;
  text-align: center;
  padding: 0 1em;
  font-weight: 700;
  bottom: 0;
  left: 0;
}

.V1 .calculator-body label {
  display: block;
  margin: 0.5rem 0;
}

.V1 .calculator-body .input-with-icon {
  position: relative;
}

.V1 .calculator-body .select-container {
  position: relative;
  margin-bottom: 0;
  font-size: 0.875rem;
}

.V1 .calculator-body .select-container:after {
  content: '';
  background-image: url('https://cdn.zuto.cloud/assets/images/icons/down-chevron.svg');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 1.3em;
  right: 0.75em;
  width: 14px;
  height: 14px;
  pointer-events: none;
}

.V1 .calculator-body .select-container select {
  outline: 0;
  padding-right: 1.875em;
  font-size: 16px;
  font-family: 'Apax Regular', Helvetica, sans-serif;
}

.V1 .calculator-body input {
  outline: 0;
  font-size: 16px;
  font-family: 'Apax Regular', Helvetica, sans-serif;
}

#calculator-container.V1 .representative-example p {
  margin-bottom: 16px;
}

.V1 .calculator-body input:focus,
.V1 .calculator-body select:focus,
.V1 .calculator-body textarea:focus {
  box-shadow: inset 0 0 0 1px #0c9ce4;
}

#calculator-container.V1 .representative-example h4 {
  font-size: 18px;
  margin-top: 0;
}

#calculator-container.V1 .representative-example p:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 600px) {
  .V1 .calculator-body p.large {
    font-size: 1.25rem;
    line-height: 1.5;
    margin: 0 0 1rem;
  }
  .V1 .calculator-body .summary h2 {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
  .V1 .calculator-body p.large {
    font-size: 1.25rem;
    line-height: 1.5;
  }
  .V1 .calculator-body .radio-container input:hover + span {
    box-shadow: inset 0 0 0 1px #0c9ce4;
  }
  .V1 .calculator-body .credit-score .checkbox.inline-fields span,
  .V1 .calculator-body .credit-score .radio.inline-fields span,
  .V1 .calculator-body .credit-score .radio.split span {
    padding: 0 1em;
    height: 3.75em;
    line-height: 3.75em;
    font-size: 16px;
  }
}

@media (min-width: 960px) {
  .V1 .calculator-body {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  .V1 .calculator-body .inline-questions {
    display: flex;
    justify-content: space-between;
  }
  .V1 .calculator-body .field-container {
    width: 48%;
    margin: 0;
    position: relative;
  }
  .V1 .calculator-body form {
    width: 70%;
    margin-right: 2rem;
  }
  .V1 .calculator-body .field-container label {
    white-space: nowrap;
  }
  #calculator-container.V1 .representative-example h4 {
    font-size: 20px;
  }
}
